import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Piqipi() {

  const data = useStaticQuery(graphql`{
  piqipi: file(relativePath: {eq: "piqipi.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)

  


  return (
    <Layout>
      <SEO title="Referencie" />
      <GatsbyImage
        image={data.piqipi.childImageSharp.gatsbyImageData}
        objectFit="cover"
        objectPosition="0% 0%"
        alt="E-shop" />  
      <div className="section-pd section-pd-bottom white-bg">
        <div className="container">
          <div className="row">
          <div className="col-12">
              <h1>E-shop pre milú detskú knihu Piqipi </h1>
              <p>Piqipi je tzv. “tichá” kniha, ktorá upúta pozornosť dieťaťa, pobaví ho a čo je dôležitejšie, pomáha rozvíjať jeho myseľ a jemné motorické zručnosti.
              <br /><br />
Pracovali sme a dodávali celý responzívny e-shop pre tento báječný tím a nápad. Celý marketing sme zvládli okolo jeho uvedenia na trh v krajinách EÚ aj v USA. Má niekoľko rôznych platobných brán v závislosti od krajiny a tiež riadenia prepravy.</p>
            </div>
            
          </div>
          
        </div>
      </div>
     
         
          

      
      



      <div className="ref-buttons">
        <AniLink className="back-to-ref" cover direction="left" to="/referencie" bg="#000" duration={1}>Späť na referencie</AniLink>
        <AniLink className="next-project" cover direction="left" to="/referencie/ultimuv" bg="#000" duration={1}>Ďalší projekt</AniLink>
        
      </div>

    </Layout>
  );

}
